import {Tag,Select,Option,Input,Button,Pagination,Form,FormItem,DatePicker,Table,TableColumn,Cascader } from 'element-ui';
import {getList,getProductList,getProductClassify} from '../api/class';
import {timestampToTime} from '../util/index.js';

// import {getBanner,getList,getConfig,searchList} from '../api/index.js';
// import {showErrorTip} from '../util/index.js';
export default {
  name:'index',
  components:{
    elTag:Tag,
    elSelect:Select,
    elOption:Option,
    elInput:Input,
    elButton:Button,
    elPagination:Pagination,
    elForm:Form,
    elFormItem:FormItem,
    elDatePicker:DatePicker,
    elTable:Table,
    elTableColumn:TableColumn,
    elCascader:Cascader,
  },
  data(){
    this.formatTime = timestampToTime;
    this.limit=20;
    this.statusMap = {
      'ended':'已结课',
      'started':'已开班',
      'not_started':'未开班'
    };
    this.seasonMap={
      'spring':'春季',
      'summer':'夏季',
      'autumn':'秋季',
      'winter':'冬季',
    }
    this.seasonList=[
      {
        key:'spring',
        name:"春季"
      },
      {
        key:'summer',
        name:"夏季"
      },
      {
        key:'autumn',
        name:"秋季"
      },
      {
        key:'winter',
        name:"冬季"
      }
    ];
    
    return {
      statusEnum:[
        {
          label:'未开班',
          value:'not_started'
        },
        {
          label:'已开班',
          value:'started'
        },
        {
          label:'已结课',
          value:'ended'
        }
      ],
      courseList:[],
      productsEnum:[],
      course_show_id:'',
      searchParams:{
        product_id:'',
        season:'',
        name:'',
        year:'',
        status:''
      },
      list:[
        // {
        //   name:'入门课',
        //   time:'2020-09-08 08:00:00',
        //   status:'进行中'
        // },
        // {
        //   name:'进阶课',
        //   time:'2020-09-08 08:00:00',
        //   status:'已结束'
        // }
      ]
    }
  },
  async mounted(){
    this.getProductList();
    this.getList();
    this.getTableData();
  },
  methods:{
    async getTableData(){
      const res = await getProductClassify();
      if(res.error===null){
        const {data = []} = res;
        let level = 0;
        const iterationFn = (obj) => {
          const {name,products=[],children=[],id} = obj;
          obj.label = name;
          obj.value = `${level}_${id}`;
          const a = products.map(item=> {item.children=null; return item;})
       
          if(children===null){
            return;
          }
          obj.children = children.concat(a);
          if(obj.children.length!==0){
            obj.children.forEach(item =>{
              level+=1;
              iterationFn(item);
            });
          }
        };
        data.forEach(item => {
          iterationFn(item)
        })
        this.courseList = data;
      }
    },
    handleChangeCourse(){
      let nodesObj = this.$refs['cascader'].getCheckedNodes()
      const{data : {id='',value=''}} = nodesObj[0];
      this.searchParams.product_id = id;
      this.course_show_id = value;
    },
    async getProductList(){
      const res = await getProductList(); 
     if(!res.error){
      this.productsEnum = res.data ||[]
     }  
    },
    async getList(){
      const res = await getList(this.searchParams); 
      if(!res.error){
        this.list = res.data||[]
      }
      // this.total = res.data.total;
    },
  
    pageTodetail(item){
      const {id} = item;
      this.$router.push(`/course/${id}`);
    },
    chooseOption(){
      this.onSearch();
    },
    onSearch(){
      this.getList();
    },
  }
}