import {get} from '../util/request.js';
import {showErrorTip} from '../util/index.js'
// 获取产品分类
const getProductClassify = async (data) => {
  const res = await get({
    url:'/api/ProductCategoryMgmtAPI',
    data
  });
  if(res.error){
    showErrorTip(res.data);
  }
  return res;
}
// 获取老师的班级列表
const getList = async (data) => {
  if(data.start_time){
    data.start_time = data.start_time /1000;
  }
  const res = await get({
    url:'/api/TeacherClassAPI',
    data
  })
  if(res.error){
    showErrorTip(res.data);
  }
  return res;
};
// 获取产品名称的枚举
const getProductList = async () => {
  const res = await get({
    url:'/api/ProductAPI',
  })
  if(res.error){
    showErrorTip(res.data);
  }
  return res;
};

export {
  getList,
  getProductList,
  getProductClassify
}